import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { TbBrandFacebook } from "react-icons/tb";
import { BsInstagram } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import Logo from "../../Assets/images/Banner/skyline-white.svg";
import "./Footer.scss";

const Footer = () => {
  return (
    <div component="footer" className="footer">
      <div className="sb-footer section-padding">
        <div className="sb-footer-links">
          {/* <div className="footer-top"> */}
          <div className="sb-footer-links-div width-50">
            <Link to="/" className="link">
              <img src={Logo} alt="logo" className="Logo" />
            </Link>

            <p className="about-txt">
              We, who are located in the Alang ship breaking yard and specialize
              in marine machinery and industrial automation as a trading company
              from India, aim at delivering high-quality products and solutions
              for ship owners together with shipyards in addition to offshore
              industries through our far-reaching connection. Skyline Marine
            </p>
          </div>
          <div className="sb-footer-links-div">
            <h4>Navigate To</h4>
            <hr></hr>
            <ul>
              <li>
                <Link to="/blogs" className="link">
                  Blog
                </Link>
              </li>
              <li>
                <Link to="/about" className="link">
                  About
                </Link>
              </li>
              <li>
                <Link to="/products" className="link">
                  Products
                </Link>
              </li>
              <li className="contact-btn">
                <Link
                  to="/contact"
                  //   id=""
                  className=" link"
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <div className="sb-footer-links-div">
            <h4>Address</h4>
            <hr></hr>
            <div>
              <p className="about-txt">Bhavnagar, Gujrat, 364001</p>
              <br />
              <a href="mailto:info@skylinemarine.co">info@skylinemarine.co</a>
              <br />
              <p className="about-txt" style={{ fontWeight: 600 }}>
                Navaz Ofthani
              </p>
              <a href="tel:+919558383856">9558383856</a>
              <p className="about-txt" style={{ fontWeight: 600 }}>
                Ayaz Sheikh
              </p>
              <a href="tel:+918905299256">8905299256</a>
            </div>
            <div className="social-media about-txt">
              <TbBrandFacebook className="icons" />
              <BsInstagram className="icons" />
              <FaLinkedinIn className="icons" />
            </div>
          </div>
          {/* </div> */}

          <hr></hr>

          <div className="sb-footer-below">
            <div className="sb-footer-copyright">
              <p>
                &copy; {new Date().getFullYear()} Skyline Marine. All rights
                reserved.
              </p>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
