import React from "react";
import { Carousel } from "react-bootstrap";
// import ship from "../../Assets/images/Banner/ship1.jpg";
import ship from "../../Assets/images/Banner/ship.jpg";
import motor from "../../Assets/images/Banner/motor.jpg";
import generator from "../../Assets/images/Banner/generator.jpg";
import ship2 from "../../Assets/images/Banner/ship2.jpg";
import AirCompressor from "../../Assets/images/Products/aircompressor-v1.jpg";
import Automation from "../../Assets/images/Products/Automation.jpg";
import Bearings from "../../Assets/images/Products/bearings-v1.jpg";
import Cables from "../../Assets/images/Products/cables-v1.jpg";
import DieselEngineAndGenerators from "../../Assets/images/Products/Diesel-engine-And-Generators-v1.jpg";
import ElectricEquipments from "../../Assets/images/Products/electric-equipments-v1.jpg";
import ElectricMotors from "../../Assets/images/Products/electric-motors-v1.jpg";
import Filters from "../../Assets/images/Products/filters-v2.jpg";
import GearMotors from "../../Assets/images/Products/gear-motors-v1.jpg";
import MarineAccesories from "../../Assets/images/Products/marine-Equipment-and-accesories-v1.jpg";
import PanelBoards from "../../Assets/images/Products/panel-boards-v3.jpg";
import marine from "../../Assets/images/Banner/marine.jpg";
import marineScrap from "../../Assets/images/Banner/marine-scrap.jpg";
import leftoverMarine from "../../Assets/images/Banner/leftover-marine.jpg";
import { Link } from "react-router-dom";
import { AiFillAlert } from "react-icons/ai"; // market specific
import { AiFillApi } from "react-icons/ai"; // globl logi
import { AiFillBug } from "react-icons/ai"; //remote tech
import { AiFillContacts } from "react-icons/ai"; // compliance
import { BiBook } from "react-icons/bi"; // packaging
import { FiAirplay } from "react-icons/fi"; //warrenty
import "./Home.scss";

const productImages = [
  { id: 1, title: "Air Compressor", src: AirCompressor },
  { id: 2, title: "Automation", src: Automation },
  { id: 3, title: "Bearings", src: Bearings },
  { id: 4, title: "Cables", src: Cables },
  {
    id: 5,
    title: "Diesel Engine And Generators",
    src: DieselEngineAndGenerators,
  },
  { id: 6, title: "Filters", src: Filters },
  { id: 7, title: "Electric Equipments", src: ElectricEquipments },
  { id: 8, title: "Electric Motors", src: ElectricMotors },
  { id: 9, title: "Gear Motors", src: GearMotors },
  {
    id: 10,
    title: "Marine Equipment and Accesories",
    src: MarineAccesories,
  },
  { id: 11, title: "Panel Boards", src: PanelBoards },
];

const servicesArr = [
  {
    id: 0,
    icon: (
      <AiFillApi
        fill="#e8e8e8"
        style={{
          height: "18px",
          width: "18px",
        }}
      />
    ),
    title: "Global Logistics and Shipping Solutions",
    description:
      "Offer specialized logistics and shipping services to ensure timely and secure delivery of parts to international destinations.",
  },
  {
    id: 1,
    icon: (
      <AiFillContacts
        fill="#e8e8e8"
        style={{
          height: "18px",
          width: "18px",
        }}
      />
    ),
    title: "Compliance and Certification Assistance",
    description:
      "Help clients navigate international regulations and standards by offering parts that comply with global maritime certifications (e.g., IMO, SOLAS).",
  },
  {
    id: 2,
    icon: (
      <AiFillBug
        fill="#e8e8e8"
        style={{
          height: "18px",
          width: "18px",
        }}
      />
    ),
    title: "Remote Technical Support",
    description:
      "Offer remote technical support services, including virtual consultations, to assist clients with installation, troubleshooting, and maintenance of automation parts.",
  },
  {
    id: 3,
    icon: (
      <BiBook
        fill="#e8e8e8"
        style={{
          height: "18px",
          width: "18px",
        }}
      />
    ),
    title: "Custom Packaging and Labeling",
    description:
      "Offer custom packaging and labeling services to meet specific requirements of different countries.",
  },
  {
    id: 4,
    icon: (
      <FiAirplay
        fill="#e8e8e8"
        style={{
          height: "18px",
          width: "18px",
        }}
      />
    ),
    title: "Warranty and After-Sales Services",
    description:
      "Offer comprehensive warranty and after-sales services, including easy return policies.",
  },
  {
    id: 5,
    icon: (
      <AiFillAlert
        fill="#e8e8e8"
        style={{
          height: "18px",
          width: "18px",
        }}
      />
    ),
    title: "Market-Specific Solutions",
    description:
      "Develop and offer solutions tailored to the specific needs of different maritime markets.",
  },
];

const recentBlogs = [
  {
    id: 1,
    image: marine,
    title: "Marine Automation in 2024: Trends, Challenges, and Opportunities",
    excerpt:
      "Marine automation has been steadily advancing over the past few years, and 2024",
  },
  {
    id: 2,
    image: marineScrap,
    title: "Effect of Marine Scrap on the Modern World",
    excerpt:
      "Marine scrap, the discarded materials from ships and marine equipment, has a significant impact on the modern world. As the maritime industry continues to grow",
  },
  {
    id: 3,
    image: leftoverMarine,
    title: "What We Can Do to Avoid Leftover Marine Products",
    excerpt:
      "The maritime industry generates a significant amount of leftover products, such as panels and automation equipment, at the end of their lifecycle",
  },
  //   {
  //     id: 4,
  //     image: ship,
  //     title: "Automation at its best",
  //     excerpt:
  //       "Automation is automation which comes from automation and only the automation, and if automation is from automation then its the real automation",
  //   },
  //   {
  //     id: 5,
  //     image: motor,
  //     title: "Automation at its best",
  //     excerpt:
  //       "Automation is automation which comes from automation and only the automation, and if automation is from automation then its the real automation",
  //   },
  //   {
  //     id: 6,
  //     image: generator,
  //     title: "Automation at its best",
  //     excerpt:
  //       "Automation is automation which comes from automation and only the automation, and if automation is from automation then its the real automation",
  //   },
];

const Home = () => {
  const duplicatedImages = [...productImages, ...productImages];
  return (
    <div className="home-container">
      <Carousel fade>
        <Carousel.Item>
          <img className="d-block w-100 img-height" src={ship} alt="banner" />
          <Carousel.Caption>
            {/* <h3>Skyline Marine</h3> */}
            <h3>Premium Parts</h3>
            <p>
              We are not just building a business, We are building trust with
              the products.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100 img-height" src={motor} alt="banner" />
          <Carousel.Caption>
            {/* <h3>Skyline Marine</h3> */}
            <h3>Reliable Solutions</h3>
            <p>Every product is tried and tested to ensure durability.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 img-height"
            src={generator}
            alt="banner"
          />
          <Carousel.Caption>
            {/* <h3>Skyline Marine</h3> */}
            <h3>Upgrade with Top-Grade Components</h3>
            <p>
              Enhance the performance and safety of your fleet with our
              top-grade marine components.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <div className="about-sec">
        <div className="image-sec">
          <img src={ship2} alt="ship" />
        </div>
        <div className="txt-sec">
          <h3>
            About <span>Skyline Marine</span>
          </h3>
          <p>
            Skyline Marine is a firm and supplier of industrial automation
            equipment located in Bhavnagar. It has been in operation since 2010.
            Skyline Marine supplies unused, refurbished or surplus industrial
            automation components such as power supplies or control panels among
            others. The firm’s main aim is to make sure that its customers get
            these products at minimum prices possible. This has made it to
            expand its markets worldwide over the years. At the Alang shipyard,
            this company has large stores where these components are kept due to
            which people regard it as reliable. Equipped men work there thus
            assuring security of the goods. Find out{" "}
            <span>
              <Link to="/about">About</Link>
            </span>{" "}
            us.
          </p>
        </div>
      </div>
      <div className="services-sec">
        {servicesArr?.map((item, index) => {
          return (
            <div key={index} className="services-info-sec">
              <div className="services-info">
                <div className="services-icon">
                  {/* <img src={item?.icon} alt="" /> */}
                  {item?.icon}
                </div>
                <div>
                  <h5 className="info-title mt-1">{item?.title}</h5>
                  <p className="info-des">{item?.description}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="products-sec">
        <h3 className="blog-sec-header">Products</h3>

        <div className="slider">
          <div className="slider-track">
            {duplicatedImages.map((item, index) => (
              <div className="slide" key={index}>
                <Link to="/products">
                  <img src={item?.src} alt={item?.title} />
                </Link>
                <h3 className="category-title">{item?.title}</h3>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="product-btn">
        <Link to="/products" className="btn btn-primary">
          Products
        </Link>
      </div>
      <div className="contact-us-sec">
        <h3>Ready to connect? Contact us Or just make a Query</h3>
        <div
          className=""
          style={{ maxWidth: "165px", width: "100%", margin: "0 auto" }}
        >
          <Link to="/contact">
            <button className="cta">
              <span>Contact Us</span>
              <svg width="15px" height="10px" viewBox="0 0 13 10">
                <path d="M1,5 L11,5"></path>
                <polyline points="8 1 12 5 8 9"></polyline>
              </svg>
            </button>
          </Link>
        </div>
      </div>
      <div className="blog-sec">
        <h3 className="blog-sec-header">Recent Blogs</h3>

        <div className="recent-blog-sec">
          {recentBlogs?.map((blog, index) => {
            return (
              <div className="blogs" key={index}>
                <Link to={`/blog/${blog.id}`}>
                  <div className="blog-img-sec">
                    <img className="blog-img" src={blog?.image} />
                  </div>
                </Link>
                <h3>{blog?.title}</h3>
                <p>
                  {blog?.excerpt}{" "}
                  <span>
                    <Link to={`/blog/${blog.id}`}>read more...</Link>
                  </span>
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Home;
